import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpIconComponent } from './icon.component';
import {
  ChevronLeftIconComponent,
  ChevronRightIconComponent,
  HomeIconComponent,
  InfoIconComponent,
  OpCursorRectangleSelectIconComponent,
  OpCursorSelectIconComponent,
  OpEnterpriseAddonsIconComponent,
  OpEraseIconComponent,
  OpFirstPersonViewIconComponent,
  OpGridMenuIconComponent,
  OpGroupIconComponent,
  OpHierarchyIconComponent,
  OpNoHierarchyIconComponent,
  OpOrthographicPerspectiveIconComponent,
  OpResizerVerticalLinesIconComponent,
  OpScissorsIconComponent,
  OpThreedReturnDefaultIconComponent,
  OpViewFitToIconComponent,
  OpViewModalIconComponent,
  OpViewThreedIconComponent,
  PackageIconComponent,
  PersonIconComponent,
  ScreenFullIconComponent,
  ShareAndroidIconComponent,
  StarFillIconComponent,
  StarIconComponent,
  XIconComponent,
  BellFillIconComponent,
  OpGitlabIssueClosedIconComponent,
  OpGitlabLabelsIconComponent,
  OpGitlabIssueOpenIconComponent,
  OpGitlabMergeRequestClosedIconComponent,
  OpGitlabMergeRequestMergedIconComponent,
  OpGitlabMergeRequestOpenIconComponent,
  OpGitlabMergeRequestReadyIconComponent,
  OpGitlabPipelineStatusCanceledIconComponent,
  OpGitlabPipelineStatusCreatedIconComponent,
  OpGitlabPipelineStatusFailedIconComponent,
  OpGitlabPipelineStatusManualIconComponent,
  OpGitlabPipelineStatusPendingIconComponent,
  OpGitlabPipelineStatusPreparingIconComponent,
  OpGitlabPipelineStatusRunningIconComponent,
  OpGitlabPipelineStatusScheduledIconComponent,
  OpGitlabPipelineStatusSkippedIconComponent,
  OpGitlabPipelineStatusSuccessIconComponent,
  OpGitlabPipelineStatusWaitingIconComponent,
  XCircleIconComponent,
} from '@openproject/octicons-angular';

@NgModule({
  imports: [
    CommonModule,

    ChevronLeftIconComponent,
    ChevronRightIconComponent,
    HomeIconComponent,
    InfoIconComponent,
    OpCursorRectangleSelectIconComponent,
    OpCursorSelectIconComponent,
    OpEnterpriseAddonsIconComponent,
    OpEraseIconComponent,
    OpFirstPersonViewIconComponent,
    OpGridMenuIconComponent,
    OpGroupIconComponent,
    OpHierarchyIconComponent,
    OpNoHierarchyIconComponent,
    OpOrthographicPerspectiveIconComponent,
    OpResizerVerticalLinesIconComponent,
    OpScissorsIconComponent,
    OpThreedReturnDefaultIconComponent,
    OpViewFitToIconComponent,
    OpViewModalIconComponent,
    OpViewThreedIconComponent,
    PackageIconComponent,
    PersonIconComponent,
    ScreenFullIconComponent,
    ShareAndroidIconComponent,
    StarFillIconComponent,
    StarIconComponent,
    XIconComponent,
    BellFillIconComponent,

    OpGitlabIssueClosedIconComponent,
    OpGitlabLabelsIconComponent,
    OpGitlabIssueOpenIconComponent,
    OpGitlabMergeRequestClosedIconComponent,
    OpGitlabMergeRequestMergedIconComponent,
    OpGitlabMergeRequestOpenIconComponent,
    OpGitlabMergeRequestReadyIconComponent,
    OpGitlabPipelineStatusCanceledIconComponent,
    OpGitlabPipelineStatusCreatedIconComponent,
    OpGitlabPipelineStatusFailedIconComponent,
    OpGitlabPipelineStatusManualIconComponent,
    OpGitlabPipelineStatusPendingIconComponent,
    OpGitlabPipelineStatusPreparingIconComponent,
    OpGitlabPipelineStatusRunningIconComponent,
    OpGitlabPipelineStatusScheduledIconComponent,
    OpGitlabPipelineStatusSkippedIconComponent,
    OpGitlabPipelineStatusSuccessIconComponent,
    OpGitlabPipelineStatusWaitingIconComponent,
    XCircleIconComponent,
  ],
  declarations: [
    OpIconComponent,
  ],
  providers: [],
  exports: [
    OpIconComponent,

    ChevronLeftIconComponent,
    ChevronRightIconComponent,
    HomeIconComponent,
    InfoIconComponent,
    OpCursorRectangleSelectIconComponent,
    OpCursorSelectIconComponent,
    OpEnterpriseAddonsIconComponent,
    OpEraseIconComponent,
    OpFirstPersonViewIconComponent,
    OpGridMenuIconComponent,
    OpGroupIconComponent,
    OpHierarchyIconComponent,
    OpNoHierarchyIconComponent,
    OpOrthographicPerspectiveIconComponent,
    OpResizerVerticalLinesIconComponent,
    OpScissorsIconComponent,
    OpThreedReturnDefaultIconComponent,
    OpViewFitToIconComponent,
    OpViewModalIconComponent,
    OpViewThreedIconComponent,
    PackageIconComponent,
    PersonIconComponent,
    ScreenFullIconComponent,
    ShareAndroidIconComponent,
    StarFillIconComponent,
    StarIconComponent,
    XIconComponent,
    BellFillIconComponent,

    OpGitlabIssueOpenIconComponent,
    OpGitlabIssueClosedIconComponent,
    OpGitlabLabelsIconComponent,
    OpGitlabMergeRequestClosedIconComponent,
    OpGitlabMergeRequestMergedIconComponent,
    OpGitlabMergeRequestOpenIconComponent,
    OpGitlabMergeRequestReadyIconComponent,
    OpGitlabPipelineStatusCanceledIconComponent,
    OpGitlabPipelineStatusCreatedIconComponent,
    OpGitlabPipelineStatusFailedIconComponent,
    OpGitlabPipelineStatusManualIconComponent,
    OpGitlabPipelineStatusPendingIconComponent,
    OpGitlabPipelineStatusPreparingIconComponent,
    OpGitlabPipelineStatusRunningIconComponent,
    OpGitlabPipelineStatusScheduledIconComponent,
    OpGitlabPipelineStatusSkippedIconComponent,
    OpGitlabPipelineStatusSuccessIconComponent,
    OpGitlabPipelineStatusWaitingIconComponent,
    XCircleIconComponent,
  ],
})
export class IconModule {}
