<op-attachment-list
  [ngClass]="{
    'op-file-section--list': true,
    'op-file-section--list_dragging': dragging > 0
  }"
  *ngIf="(attachments$ | async).length"
  [attachments]="attachments$ | async"
  [collectionKey]="collectionKey"
  [showTimestamp]="showTimestamp"
  (attachmentRemoved)="attachmentRemoved.emit()"
></op-attachment-list>

<input
  *ngIf="allowUploading && resource.canAddAttachments"
  #hiddenFileInput
  type="file"
  id="attachment_files"
  name="attachment_files"
  (change)="onFilePickerChanged()"
  hidden
  multiple
/>

<button
  *ngIf="allowUploading && resource.canAddAttachments"
  [attr.aria-label]="text.uploadLabel"
  data-test-selector="op-attachments--drop-box"
  [ngClass]="{
    'hide-when-print': true,
    'op-file-section--drop-box': true,
    'op-file-section--drop-box_dragging': dragging > 0,
    'op-file-section--drop-box_dragging-over': draggingOverDropZone,
    'op-file-section--drop-box_float': (attachments$ | async).length
  }"
  type="button"
  (click)="triggerFileInput()"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDropFiles($event)"
>
  <span
    class="spot-icon spot-icon_add-attachment op-file-section--drop-box-icon"
  ></span>
  <span
    *ngIf="(attachments$ | async).length > 0"
    class="op-file-section--drop-box-text"
    [textContent]="text.dropFiles"
  ></span>
  <span
    *ngIf="(attachments$ | async).length === 0"
    class="op-file-section--drop-box-text"
    [textContent]="text.dropClickFiles"
  ></span>
</button>

<div
  *ngIf="allowUploading && resource.canAddAttachments"
  class="op-file-section--actions hide-when-print"
>
  <button
    *ngIf="!externalUploadButton"
    type="button"
    class="spot-link"
    (click)="triggerFileInput()"
    data-test-selector="op-attachments--upload-button"
  >
    <span class="spot-icon spot-icon_add-attachment"></span>
    <span [textContent]="text.uploadLabel"></span>
  </button>
</div>
